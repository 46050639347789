import React from "react";
import { Toast } from "antd-mobile";
import InputBlock from "../../components/InputBlock";
import { register } from "../../api/register";
import { isEmpty } from "../../utils/common";
import check_icon from "../../assets/img/check.png";
import uncheck_icon from "../../assets/img/uncheck.png";
import { State } from "./index.interface";

import styles from "./index.module.less";
class WriteInfo extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      formData: {
        name: "",
        password: "",
        confirmPassword: "",
        inviterId: "",
        inviterName: "",
      },
      isCheck: false,
      disabled: false,
    };
  }

  componentDidMount() {
    document.title = "无界时空链";
    const { formData } = this.state;
    const info = JSON.parse(localStorage.getItem("registerInfo") || "");
    this.setState({
      formData: {
        ...formData,
        inviterId: info.inviteId,
        inviterName: info.inviteName,
      },
      disabled: info.inviteId,
    });
  }

  handleChange = (val: string, key: string) => {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [key]: val,
      },
    });
  };

  handelSwitchCheck = () => {
    const { isCheck } = this.state;
    this.setState({ isCheck: !isCheck });
  };

  // 注册
  handleSubmit = async () => {
    const { history } = this.props;
    const { formData, isCheck } = this.state;
    if (isEmpty(formData) || !isCheck) {
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      Toast.fail("两次输入的密码不一致", 1);
      return;
    }
    if (!isEmpty(formData) && isCheck) {
      const res: any = await register({
        ...JSON.parse(localStorage.getItem("registerInfo") || ""),
        inviteId: formData.inviterId,
        inviteName: formData.inviterName,
        memberName: formData.name,
        password: formData.password,
      });
      if (res.code === 200) {
        Toast.success("注册成功", 2);
        setTimeout(() => {
          history.push("/register_success");
        }, 2000);
      } else {
        Toast.fail(res.msg, 2);
      }
    }
  };

  // 跳转协议
  handleToAggreement = () => {
    window.location.href = "http://resource.wujieuni.com/xieyi/hetong.html";
  };

  render() {
    const { formData, isCheck, disabled } = this.state;
    return (
      <main className={styles.main}>
        <section>
          <div className={styles.form}>
            <InputBlock
              title="用户名"
              titleWidth="1rem"
              onChange={val => this.handleChange(val, "name")}
              value={formData.name}
              placeholder="请输入您的用户名"
            />
            <InputBlock
              title="设置密码"
              titleWidth="1rem"
              type="password"
              onChange={val => this.handleChange(val, "password")}
              value={formData.password}
              placeholder="请输入数字、英文大小写密码"
            />
            <InputBlock
              title="确认密码"
              titleWidth="1rem"
              type="password"
              onChange={val => this.handleChange(val, "confirmPassword")}
              value={formData.confirmPassword}
              placeholder="请再次确认密码"
            />
            <InputBlock
              titleWidth="1rem"
              title="链接人ID"
              disabled={disabled}
              onChange={val => this.handleChange(val, "inviterId")}
              value={formData.inviterId}
              placeholder="请输入链接人ID"
            />
            <InputBlock
              titleWidth="1rem"
              title="链接人会员名"
              disabled={disabled}
              onChange={val => this.handleChange(val, "inviterName")}
              value={formData.inviterName}
              placeholder="请输入链接人会员名"
            />

            <span className={styles.agreement}>
              <img
                src={isCheck ? check_icon : uncheck_icon}
                alt=""
                onClick={this.handelSwitchCheck}
              />
              我已阅读并同意
              <i onClick={this.handleToAggreement}>《无界V店协议》</i>
            </span>

            <span
              className={styles.button}
              style={isEmpty(formData) || !isCheck ? { opacity: ".6" } : {}}
              onClick={this.handleSubmit}
            >
              确认
            </span>
          </div>
        </section>

        <span className={styles.tips}>全国服务热线：400-020-9995</span>
      </main>
    );
  }
}

export default WriteInfo;
