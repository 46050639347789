import React from "react";
import { Switch, Route } from "react-router-dom";
import ProductDetail from "./ProductDetail";
import CommentList from "./CommentList";
import AppDownload from "./AppDownload";
import Package from "./Package";
import Register from "./Register";
import WriteInfo from "./WriteInfo";
import Preview from "./Preview";
import RegisterSuccess from "./RegisterSuccess";
import Store from "./Store"; // 店铺
import VStore from "./VStore"; // V店
import VStoreCategory from "./VStoreCategory"; // V店
import Demo from "./Demo";

const Routes = () => {
  return (
    <Switch>
      <Route path="/demo" component={Demo} />
      {/* /product_detail?share= */}
      <Route path="/product_detail" component={ProductDetail} />
      <Route path="/comment_list" component={CommentList} />
      <Route path="/download" component={AppDownload} />
      <Route path="/package" component={Package} />
      <Route path="/app-download" component={Register} />
      <Route path="/register_success" component={RegisterSuccess} />
      <Route path="/write_info" component={WriteInfo} />
      <Route path="/preview" component={Preview} />
      {/* /v_store?storeId */}
      <Route path="/store" component={Store} />
      {/* /v_store?storeId&memberId */}
      <Route path="/v_store" component={VStore} />
      {/* /v_category?memberId&categoryId&categoryName */}
      <Route path="/v_category" component={VStoreCategory} />
    </Switch>
  );
};

export default Routes;
