import React, { SFC } from "react";
import { withRouter } from "react-router-dom";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const RecommendItem: SFC<ComponentProps> = ({
  info = {
    commonId: 0,
    goodsName: "",
    goodsPrice: 0,
    vnum: 0,
    imageSrc: "",
  },
  isSharePage,
  token,
  history,
}) => {
  const handleClick = () => {
    history.push(
      `/product_detail?commonId=${info.commonId}&token=${token}&share=${isSharePage}`
    );
    window.location.reload();
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <img src={info.imageSrc} alt="" />
      <span className={styles.name}>{info.goodsName}</span>
      <div className={styles.info}>
        <span className={styles.price}>¥{info.goodsPrice}</span>
        {info.vnum > 0 && <span className={styles.vipLevel}>{info.vnum}V</span>}
      </div>
    </div>
  );
};

export default withRouter(RecommendItem);
