import React, { SFC } from "react";
import styles from "./index.module.less";

const CommonFooter: SFC = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.line} />
      <div className={styles.textWrapper}>
        <div>享无界 • 行无界</div>
        <div>Link to better</div>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export default CommonFooter;
