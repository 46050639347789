import React, { SFC } from "react";
import classNames from "classnames";
import icon_middle from "../../../../../../assets/img/store/icon_mode_middle.png";
import icon_large from "../../../../../../assets/img/store/icon_mode_large.png";
import { ComponentProps } from "./index.interface";

import styles from "./index.module.less";
const modeIcon = {
  middle: icon_middle,
  large: icon_large,
};
const sortArray = [
  { key: "", label: "综合" },
  { key: "goodsSaleNum", label: "销量" },
  { key: "goodsPrice", label: "价格" },
  { key: "vnum", label: "V值" },
];

const SortBar: SFC<ComponentProps> = ({
  sort = "",
  asc = true,
  mode = "middle",
  onSortClick = () => {},
  onModeClick = () => {},
}) => {
  const renderIcon = (key: string) => {
    if (key === sort) {
      if (asc) {
        return (
          <div className={styles.icon}>
            <div className={classNames(styles.supIcon, styles.activeSupIcon)} />
            <div className={styles.subIcon} />
          </div>
        );
      }
      return (
        <div className={styles.icon}>
          <div className={styles.supIcon} />
          <div className={classNames(styles.subIcon, styles.activeSubIcon)} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.sortBar}>
      <div className={styles.sort}>
        {sortArray.map(item => (
          <div
            key={item.key}
            className={classNames(
              styles.sortItem,
              item.key === sort && styles.activeItem
            )}
            onClick={() => {
              onSortClick(item.key);
            }}
          >
            <div className={styles.label}> {item.label}</div>
            <div className={styles.iconWrapper}>{renderIcon(item.key)}</div>
          </div>
        ))}
      </div>
      <div className={styles.others}>
        <div
          className={styles.modeItem}
          onClick={() => {
            onModeClick();
          }}
        >
          <img src={modeIcon[mode]} alt={mode} />
        </div>
      </div>
    </div>
  );
};

export default SortBar;
