import React, { Fragment } from "react";
import { Toast } from "antd-mobile";
import classNames from "classnames";
import { getGoods } from "../../../../api/store";
import ProductItem from "../../../../components/ProductItem";
import EmptyList from "../../../../components/EmptyList";
import CommonFooter from "../../../../components/CommonFooter";
import ScrollLoading from "../../../../components/ScrollLoading";
import styles from "./index.module.less";
import { Props, State } from "./index.interface";
import SortBar from "./components/SortBar";

class Goods extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loadingMore: false,
      hasMore: false,
      page: 1,
      size: 30,
      sort: "",
      mode: "middle",
      asc: true,
      list: [],
    };
  }
  componentDidMount() {
    const { storeId } = this.props;
    if (storeId) {
      this.getData(false);
    }
    document.addEventListener("scroll", this.handleScroll);
  }
  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.searchValue !== this.props.searchValue) {
      this.getData(false, nextProps.searchValue);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    const { loadingMore, hasMore, page } = this.state;
    // TODO: 如果改布局或者滑动区域的话这里应该是要改的..
    if (
      !loadingMore &&
      hasMore &&
      window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 10
    ) {
      this.setState(
        {
          page: page + 1,
        },
        () => {
          this.getData(true);
        }
      );
    }
  };
  getData = async (isLoadingMore: boolean, searchValue?: string) => {
    const { storeId } = this.props;
    const { page, size, sort, asc, list } = this.state;
    if (!isLoadingMore) {
      Toast.loading("加载中");
    }
    const res: any = await getGoods({
      storeId,
      goodsName: searchValue || this.props.searchValue,
      page,
      size,
      sort: sort ? `${sort},${asc ? "asc" : "desc"}` : "",
    });
    if (res && res.code === 200) {
      const { content, last } = res.body;
      if (isLoadingMore) {
        this.setState({
          list: list.concat(content),
          hasMore: !last,
          loadingMore: false,
        });
      } else {
        this.setState({
          list: content,
          hasMore: !last,
          loadingMore: false,
        });
      }
    } else {
      this.setState({
        loadingMore: false,
        hasMore: false,
      });
    }
    if (!isLoadingMore) {
      Toast.hide();
    }
  };
  handleSortClick = (sortkey: string) => {
    const { sort, asc } = this.state;
    const newState: any = {
      page: 1,
    };
    if (sortkey === sort) {
      newState.asc = !asc;
    } else {
      newState.sort = sortkey;
    }
    this.setState(
      {
        ...this.state,
        ...newState,
      },
      () => {
        this.getData(false);
      }
    );
  };
  handleModeClick = () => {
    const { mode } = this.state;

    this.setState({
      mode: mode === "large" ? "middle" : "large",
    });
  };
  render() {
    const { list, sort, asc, mode, loadingMore, hasMore } = this.state;
    const { onGoodsItemClick } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.sortBar}>
          <SortBar
            onSortClick={(sort: string) => this.handleSortClick(sort)}
            onModeClick={() => this.handleModeClick()}
            sort={sort}
            asc={asc}
            mode={mode}
          />
        </div>
        <div className={styles.main}>
          {list.length ? (
            <div className={styles.listWrapper}>
              <div
                className={classNames(
                  styles.list,
                  mode === "large" && styles.largeList
                )}
              >
                {list.map((goods: any) => {
                  return (
                    <Fragment key={goods.commonId}>
                      <ProductItem
                        commonId={goods.commonId}
                        goodsName={goods.goodsName}
                        goodsPrice={goods.goodsPrice}
                        vnum={goods.vnum}
                        imageSrc={goods.imageSrc}
                        goodsSaleNum={goods.goodsSaleNum}
                        showSaleNum={true}
                        secondhand={goods.secondHand}
                        size={mode}
                        onClick={onGoodsItemClick}
                      />
                    </Fragment>
                  );
                })}
              </div>
              <ScrollLoading loading={loadingMore} hasMore={hasMore} />
            </div>
          ) : (
            <EmptyList />
          )}
        </div>
        <CommonFooter />
      </div>
    );
  }
}
export default Goods;
