import React, { SFC, useEffect, useState } from "react";
import classNames from "classnames";
import icon_top from "../../assets/img/store/icon_to_top.png";
import styles from "./index.module.less";
const BackToTop: SFC = () => {
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    // 监听
    window.addEventListener("scroll", handleScroll);
    // 销毁
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleScroll = () => {
    setIsShow(window.scrollY > 200);
  };
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div
      className={classNames(styles.backToTop, isShow && styles.visible)}
      onClick={handleClick}
    >
      <img src={icon_top} alt="" />
    </div>
  );
};

export default BackToTop;
