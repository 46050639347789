import React, { SFC } from "react";
import icon_empty from "../../assets/img/store/noRecords.png";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const EmptyList: SFC<ComponentProps> = ({
  icon = icon_empty,
  text = "暂无数据",
}) => {
  return (
    <div className={styles.empty}>
      {<img src={icon} alt="" />}
      <p>{text}</p>
    </div>
  );
};

export default EmptyList;
