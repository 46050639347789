import * as React from "react";
import styles from "./index.module.less";

class ChildThree extends React.Component {
  render() {
    return <div className={styles.container}>我是子路由3</div>;
  }
}

export default ChildThree;
