import React, { SFC } from "react";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const CouponItem: SFC<ComponentProps> = ({
  handleClick = () => {},
  info = {
    limitAmount: 0,
    templateId: 0,
    useStartTime: "",
    useEndTime: "",
    templatePrice: 0,
    complete: false,
    receive: false,
  },
}) => {
  const handleReceive = () => {
    if (!info.receive) {
      handleClick(info.templateId);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.priceInfo}>
          <span className={styles.price}>
            <i>￥</i>
            {info.templatePrice}
          </span>
          <span className={styles.type}>店铺优惠券</span>
        </div>
        <span className={styles.limit}>满{info.limitAmount}使用</span>
        <span className={styles.time}>
          使用期：{info.useStartTime}-{info.useEndTime}
        </span>
      </div>
      <span
        onClick={handleReceive}
        // className={info.receive ? styles.disableButton : ""}
      >
        {info.receive ? "已领取" : "立即领取"}
      </span>
    </div>
  );
};

export default CouponItem;
