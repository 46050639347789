import React, { SFC } from "react";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const ProductItem: SFC<ComponentProps> = ({
  commonId = 0,
  goodsName = "",
  goodsPrice = 0,
  vnum = 0,
  imageSrc = "",
  size = "middle", // small large
  secondhand = false,
  goodsSaleNum = 0,
  showSaleNum = false,
  onClick = () => {},
}) => {
  return (
    <div className={styles.productItem} onClick={() => onClick(commonId)}>
      {secondhand && <div className={styles.secondhand} />}
      <div className={styles[`${size}Product`]}>
        <div className={styles.imgWrapper}>
          <img src={imageSrc} alt="商品图片" />
        </div>
        <div className={styles.info}>
          <div className={styles.nameWrapper}>
            <div className={styles.name}>{goodsName}</div>
          </div>
          <div className={styles.price}>¥{goodsPrice}</div>
          {vnum > 0 && <div className={styles.vipLevel}>{vnum}V</div>}
          {showSaleNum && (
            <div className={styles.num}>销量：{goodsSaleNum}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
