import React, { SFC } from "react";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const ScrollLoading: SFC<ComponentProps> = ({
  loading = false,
  hasMore = false,
}) => {
  if (loading) {
    return <div className={styles.scrollLoading}>正在加载</div>;
  } else if (!loading && hasMore) {
    return <div className={styles.scrollLoading}>滑动加载更多</div>;
  } else if (!loading && !hasMore) {
    return <div className={styles.scrollLoading}>没有更多了</div>;
  }
  return null;
};
export default ScrollLoading;
