import React from "react";
import qs from "qs";
import { Toast } from "antd-mobile";
import logo from "../../assets/img/logo.png";
import { sendCode, verifyRegisterCode } from "../../api/register";
import InputBlock from "../../components/InputBlock";
import { isEmpty } from "../../utils/common";
import { State } from "./index.interface";

import styles from "./index.module.less";
let timer: any = null;
class Register extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      formData: {
        phone: "",
        code: "",
      },
      count: 90,
    };
  }

  componentDidMount() {
    document.title = "无界时空链";
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  handleChange = (val: string, key: string) => {
    const { formData } = this.state;
    if (key === "phone") {
      this.setState({
        formData: {
          ...formData,
          [key]: val.slice(0, 11),
        },
      });
    } else {
      this.setState({
        formData: {
          ...formData,
          [key]: val,
        },
      });
    }
  };

  // 发送验证码
  handleSendCode = async () => {
    const { formData } = this.state;
    if (!this.verifyPhone(formData.phone)) {
      return;
    }
    const res: any = await sendCode({
      mobile: formData.phone,
    });
    if (res.code === 200) {
      Toast.success(res.msg, 2);
      timer = setInterval(() => {
        const { count } = this.state;
        if (count <= 0) {
          clearInterval(timer);
          timer = null;
        }
        this.setState({
          count: count - 1,
        });
      }, 1000);
    } else {
      Toast.fail(res.msg, 1);
    }
  };

  // 验证注册验证码
  handleSubmit = async () => {
    const { history, location } = this.props;
    const { formData } = this.state;
    if (isEmpty(formData)) {
      return;
    }
    const res: any = await verifyRegisterCode({
      mobile: formData.phone,
      code: formData.code,
    });
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (res.code === 200) {
      localStorage.setItem(
        "registerInfo",
        JSON.stringify({
          mobile: formData.phone,
          mobileCode: formData.code,
          code: res.body,
          inviteId: params.inviteId || "",
          inviteName: params.inviteName || "",
        })
      );
      history.push("/write_info");
    } else {
      Toast.fail(res.msg, 1);
    }
  };

  // 手机号规则验证
  verifyPhone = (phone: string) => {
    var reg = /^[1]\d{10}$/;
    if (!reg.test(phone)) {
      return false;
    }
    return true;
  };

  // 跳转app下载页
  handleToAppDownload = () => {
    const { history } = this.props;
    history.push("/download");
  };

  render() {
    const { formData, count } = this.state;
    return (
      <main className={styles.main}>
        <section>
          <img className={styles.logo} src={logo} alt="" />
          <div className={styles.form}>
            <InputBlock
              title="手机号"
              type="number"
              onChange={val => this.handleChange(val, "phone")}
              value={formData.phone}
              placeholder="请输入手机号"
            />
            <InputBlock
              title="验证码"
              onChange={val => this.handleChange(val, "code")}
              value={formData.code}
              placeholder="请输入验证码"
              extra={
                timer ? (
                  <span className={styles.countdown}>重新发送{count}</span>
                ) : (
                  <span
                    className={styles.getcode}
                    style={
                      this.verifyPhone(formData.phone) ? {} : { opacity: ".6" }
                    }
                    onClick={this.handleSendCode}
                  >
                    获取验证码
                  </span>
                )
              }
            />

            <span
              className={styles.button}
              style={isEmpty(formData) ? { opacity: ".6" } : {}}
              onClick={this.handleSubmit}
            >
              提交
            </span>

            <span className={styles.existed} onClick={this.handleToAppDownload}>
              已有账户？
            </span>
          </div>
        </section>

        <span className={styles.tips}>全国服务热线：400-020-9995</span>
      </main>
    );
  }
}

export default Register;
