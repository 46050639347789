/* eslint-disable jsx-a11y/alt-text */
import React, { createRef } from "react";
import qs from "qs";
import dsbridge from "dsbridge";
import { Carousel, Modal, Toast } from "antd-mobile";
import CommentItem from "../../components/CommentItem";
import CouponItem from "../../components/CouponItem";
import RecommendItem from "../../components/RecommendItem";
import Tabs from "../../components/Tabs";

import {
  getDetail,
  getRecommendList,
  getGoodsDesc,
  getFavoriteState,
  getCouponList,
  addCollect,
  cancelCollect,
  addToShoppingCart,
  receiveCoupon,
  putaway,
  addHistory,
  getStoreLevel,
} from "../../api/productDetail";
import play from "../../assets/img/play.png";

import shop_icon from "../../assets/img/shop.png";
import shoppingCart_icon from "../../assets/img/shoppingCart.png";
import arrow_icon from "../../assets/img/arrow.png";
// import diamond_icon from "../../assets/img/diamond.png";
import close_icon from "../../assets/img/close.png";
import contact_icon from "../../assets/img/contact.png";
import GoodsInfo from "./components/GoodsInfo";
import SpecModal from "./components/SpecModal";
import { Props, State } from "./index.interface";
import styles from "./index.module.less";
let isOn = true;
class ProductDetail extends React.Component<Props, State> {
  timer: number;
  comment: React.RefObject<any>;
  detail: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.state = {
      shareUserid: 0,
      isSharePage: false,
      isShowPlayIcon: true, // 是否显示播放按钮
      pending: true,
      commonId: 0,
      activeTab: 0,
      showSpecModal: false, // 规格弹窗
      showCouponModal: false, // 优惠券弹窗
      modalType: "spec", // 弹窗类型，spec为规格，buy为立即购买，shopping为购物车
      goodsDetail: {
        goodsId: 0,
        commonId: 0,
        goodsName: "",
        goodsPrice: 0,
        discountPrice: null,
        vnum: 0,
        goodsSaleNum: 0,
        imageSrc: "",
        specJson: [],
        goodsSpecValueJson: [],
        goodsImageList: [],
        goodsList: [],
        secondHand: false,
        inStore: false,
        videoSrc: "",
        promotionPrice: 0,
        promotionState: 0,
        promotionCountDownTime: 0,
        promotionType: 0,
      },
      evaluateGoodsTotal: 0, // 评论数量
      evaluateGoodsVoList: [], // 评论列表
      storeInfo: {
        // 店铺信息
        storeName: "",
        storeAvatarUrl: "",
        storeId: 0,
        storeCollect: 0,
        gradeId: 0,
        levelPic: "",
        memberId: 0,
      },
      recommendList: [], // 推荐商品列表
      goodsMobileBodyVoList: [], // 商品详情图片
      isCollect: false, // 是否收藏商品
      couponList: [], // 优惠券列表
      token: "",
    };
    this.comment = createRef();
    this.detail = createRef();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.pageScrollEvent);

    document.title = "商品详情";
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (params.token) {
      localStorage.setItem("token", params.token);
    } else {
      localStorage.removeItem("token");
    }
    this.setState(
      {
        commonId: Number.parseInt(params.commonId, 10) || 0,
        isSharePage: params.share === "true",
        token: params.token,
        shareUserid: Number.parseInt(params.shareUserid, 10) || 0,
      },
      () => {
        if (localStorage.getItem("token")) {
          this.addHistory();
        }
        this.getGoodsDetail();
        this.getRecommendList();
        this.getGoodsDesc();
        this.getFavoriteState();
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("scroll", this.pageScrollEvent);
  }

  // componentDidUpdate(prevProps: Props, prevState: State) {
  //   const { location } = this.props;
  //   if (location.search !== prevProps.location.search) {
  //     const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  //     this.setState(
  //       {
  //         commonId: Number.parseInt(params.id, 10) || 743,
  //       },
  //       () => {
  //         this.getGoodsDetail();
  //         this.getRecommendList();
  //         this.getGoodsDesc();
  //         this.getFavoriteState();
  //         this.getCouponList();
  //       }
  //     );
  //   }
  // }

  // 页面滚动事件
  pageScrollEvent = () => {
    if (!isOn) {
      return; //通过isOn的值来判断函数是否正在执行，如果正在执行，则直接return;
    }
    isOn = false;
    this.timer = window.setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop;
      const commentTop = this.comment.current.offsetTop; // 评论块到页面顶部的距离
      const detailTop = this.detail.current.offsetTop; // 详情块到页面顶部的距离
      if (scrollTop < commentTop - 60) {
        this.setState({
          activeTab: 0,
        });
      } else if (scrollTop < detailTop - 60) {
        this.setState({
          activeTab: 2,
        });
      } else {
        this.setState({
          activeTab: 1,
        });
      }
      isOn = true; //通过改变isOn的值来释放关卡，允许下一个访问者进来。
    }, 500);
  };

  // 添加浏览记录
  addHistory = async () => {
    const { commonId } = this.state;
    const res: any = await addHistory({
      commonId,
    });
  };

  // 获取商品详情
  getGoodsDetail = async () => {
    const { commonId } = this.state;
    const res: any = await getDetail({
      commonId,
    });
    if (res.code === 200) {
      const list = res.datas.goodsDetail.goodsSpecValueJson.map(
        (item: { goodsId: number }) => {
          let goodsPrice = 0;
          let imageSrc = "";
          let goodsSpecString = "";
          let goodsStorage = 0;
          let vnum = 0;
          let appPrice0 = 0;
          let promotionState = 0;
          let promotionType = 0;
          res.datas.goodsDetail.goodsList.forEach(
            (goods: {
              goodsId: number;
              goodsPrice0: number;
              imageSrc: string;
              goodsSpecString: string;
              goodsStorage: number;
              vnum: number;
              appPrice0: number;
              promotionState: number;
              promotionType: number;
            }) => {
              if (item.goodsId === goods.goodsId) {
                goodsPrice = goods.appPrice0;
                imageSrc = goods.imageSrc;
                goodsSpecString = goods.goodsSpecString.replace(/;/g, " ");
                goodsStorage = goods.goodsStorage;
                vnum = goods.vnum;
                appPrice0 = goods.appPrice0;
                promotionState = goods.promotionType;
                promotionType = goods.promotionType;
              }
            }
          );
          return {
            ...item,
            goodsPrice,
            imageSrc,
            goodsSpecString,
            goodsStorage,
            vnum,
            appPrice0,
            promotionState,
            promotionType,
          };
        }
      );
      res.datas.goodsDetail.goodsImageList.forEach((item: any) => {
        item.isVideo = false;
      });
      if (res.datas.goodsDetail.videoSrc) {
        res.datas.goodsDetail.goodsImageList.unshift({
          videoSrc: res.datas.goodsDetail.videoSrc,
          imageSrc: res.datas.goodsDetail.goodsImageList[0].imageSrc,
          isVideo: true,
        });
      }
      this.setState(
        {
          goodsDetail: { ...res.datas.goodsDetail, goodsSpecValueJson: list },
          evaluateGoodsTotal: res.datas.evaluateGoodsTotal,
          evaluateGoodsVoList: res.datas.evaluateGoodsVoList.slice(0, 3),
          storeInfo: res.datas.storeInfo,
          pending: false,
        },
        () => {
          this.getStoreInfo();
        }
      );
      this.getCouponList(res.datas.storeInfo.storeId);
    }
  };

  // 获取店铺信息
  getStoreInfo = async () => {
    const { storeInfo } = this.state;
    const res: any = await getStoreLevel(storeInfo.storeId);
    if (res.code === 200) {
      this.setState({
        storeInfo: {
          ...storeInfo,
          levelPic: res.body.levelPic,
        },
      });
    }
  };

  // 获取推荐商品列表
  getRecommendList = async () => {
    const { commonId } = this.state;
    const res: any = await getRecommendList(commonId);
    if (res.code === 200) {
      this.setState({
        recommendList: res.body,
      });
    }
  };

  // 获取商品详细描述
  getGoodsDesc = async () => {
    const { commonId } = this.state;
    const res: any = await getGoodsDesc(commonId);
    if (res.code === 200) {
      const newList: string[] = [];
      res.datas.goodsMobileBodyVoList.forEach(
        (item: { type: string; value: string }) => {
          if (item.type === "image") {
            newList.push(item.value);
          }
        }
      );
      this.setState({
        goodsMobileBodyVoList: newList,
      });
    }
  };

  // 是否收藏商品
  getFavoriteState = async () => {
    const { commonId } = this.state;
    const res: any = await getFavoriteState({
      commonId,
    });
    if (res.code === 200) {
      this.setState({
        isCollect: res.datas.isExist === 1,
      });
    }
  };

  // 获取优惠券列表
  getCouponList = async (storeId: number) => {
    const res: any = await getCouponList(storeId);
    if (res.code === 200) {
      this.setState({
        couponList: res.body,
      });
    }
  };

  // 跳转相应位置
  scrollToAnchor = (anchorName: any) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  // 点击tab
  changeTab = (index: number) => {
    this.setState({
      activeTab: index,
    });
    switch (index) {
      case 0:
        this.scrollToAnchor("topBlock");
        break;
      case 1:
        this.scrollToAnchor("detailBlock");
        break;
      case 2:
        this.scrollToAnchor("commentBlock");
        break;
      default:
        break;
    }
  };

  // 关闭规格弹窗
  handleCloseSpecModal = () => {
    this.setState({
      showSpecModal: false,
    });
  };

  // 打开规格弹窗
  handleShowSpecModal = (type: string) => {
    this.setState({
      modalType: type,
      showSpecModal: true,
    });
  };

  // 关闭优惠券弹窗
  handleCloseCouponModal = () => {
    this.setState({
      showCouponModal: false,
    });
  };

  // 打开优惠券弹窗
  handleShowCouponModal = () => {
    this.setState({
      showCouponModal: true,
    });
  };

  // 收藏/取消收藏商品
  handleChangeFavoriteState = async () => {
    if (!localStorage.getItem("token")) {
      dsbridge.call("login");
      return;
    }
    const { isCollect, commonId } = this.state;
    if (isCollect) {
      const res: any = await cancelCollect({
        commonId,
      });
      if (res.code === 200) {
        this.setState({
          isCollect: !isCollect,
        });
        Toast.success("取消收藏成功");
      } else {
        Toast.success("取消收藏失败");
      }
    } else {
      const res: any = await addCollect({
        commonId,
        clientType: "android",
      });
      if (res.code === 200) {
        this.setState({
          isCollect: !isCollect,
        });
        Toast.success("收藏成功");
      } else {
        Toast.success("收藏失败");
      }
    }
  };

  // 加入购物车
  addToShoppingCart = async (sum: number, currentSpecProductInfo: any) => {
    const { storeInfo } = this.state;
    if (!localStorage.getItem("token")) {
      dsbridge.call("login");
      return;
    }
    if (currentSpecProductInfo.goodsId === 0) {
      Toast.info("请选择商品规格");
      return;
    }
    if (currentSpecProductInfo.goodsStorage < sum) {
      Toast.info("超出商品库存");
      return;
    }
    const { shareUserid } = this.state;
    const res: any = await addToShoppingCart({
      buyData: JSON.stringify([
        {
          buyNum: sum,
          goodsId: currentSpecProductInfo.goodsId,
          shareUserid: shareUserid,
        },
      ]),
      clientType: "android",
      storeId: storeInfo.storeId,
      cartData: {},
    });
    if (res.code === 200) {
      Toast.success("添加成功");
    }
    this.handleCloseSpecModal();
  };

  // 立即购买
  quickBuy = (sum: number, currentSpecProductInfo: any) => {
    if (!localStorage.getItem("token")) {
      dsbridge.call("login");
      return;
    }
    if (currentSpecProductInfo.goodsId === 0) {
      Toast.info("请选择商品规格");
      return;
    }
    if (currentSpecProductInfo.goodsStorage < sum) {
      Toast.info("超出商品库存");
      return;
    }
    const { shareUserid } = this.state;

    dsbridge.call("buy", {
      buyData: JSON.stringify([
        {
          buyNum: sum,
          goodsId: currentSpecProductInfo.goodsId,
          shareUserid: shareUserid,
        },
      ]),
    });
    this.handleCloseSpecModal();
  };

  // 跳转店铺详情
  handleToShop = () => {
    const { storeInfo, isSharePage } = this.state;

    if (isSharePage) {
      this.props.history.push(`/store?storeId=${storeInfo.storeId}`);
      window.scrollTo(0, 0);
    } else {
      dsbridge.call("goToShop", storeInfo.storeId);
    }
  };

  // 跳转购物车
  handleToShoppingCart = () => {
    dsbridge.call("goToShoppingCart");
  };

  // 跳转评价列表
  handleToCommentList = () => {
    const { commonId } = this.state;
    const { history } = this.props;
    history.push(`/comment_list?commonId=${commonId}`);
  };

  // 领取优惠券
  handleReceiveCoupon = async (id: number) => {
    if (!localStorage.getItem("token")) {
      dsbridge.call("login");
      return;
    }
    const { couponList } = this.state;
    const res: any = await receiveCoupon({
      templateId: id,
    });
    if (res.code === 200) {
      Toast.success("领取成功");
      const list = couponList;
      list.forEach(item => {
        if (item.templateId === id) {
          item.receive = true;
        }
      });
      this.setState({
        couponList: list,
      });
    }
  };
  onekeyCloseCouponModal = () => {
    const { couponList } = this.state;
    couponList.forEach(i => {
      this.handleReceiveCoupon(i.templateId);
    });
  };

  // 商品上架
  handlePutaway = async (labelId: number) => {
    const { commonId } = this.state;
    const res: any = await putaway({
      labelId,
      goodsCommonId: commonId,
    });
    if (res.code === 200) {
      Toast.info("上架成功");
      this.getGoodsDetail();
    }
  };

  // 播放视频
  handlePlay = () => {
    const el: any = document.getElementById("video");
    el.play();
    el.addEventListener("ended", () => {
      el.load();
      this.setState({
        isShowPlayIcon: true,
      });
    });
    this.setState({
      isShowPlayIcon: false,
    });
  };

  // 获取当前规格商品信息
  getGoodsInfo = (goodsInfo: {
    goodsId: number;
    goodsPrice: number;
    imageSrc: string;
    goodsSpecString: string;
    goodsStorage: number;
    vnum: number;
    promotionState: number;
  }) => {
    const { goodsDetail } = this.state;
    this.setState({
      goodsDetail: {
        ...goodsDetail,
        promotionPrice: goodsInfo.goodsPrice,
        promotionState: goodsInfo.promotionState,
      },
    });
  };

  // 联系客服
  handelContact = () => {
    const { storeInfo, commonId, goodsDetail } = this.state;
    const { memberId, storeName } = storeInfo;
    const { goodsName, goodsPrice, imageSrc } = goodsDetail;
    dsbridge.call("toCustomerService", {
      memberId,
      storeName,
      goodsInfo: {
        commonId,
        goodsName,
        goodsPrice,
        imageSrc,
      },
    });
  };

  render() {
    const tabList = [
      {
        title: "商品",
        id: 0,
      },
      {
        title: "评价",
        id: 2,
      },
      {
        title: "详情",
        id: 1,
      },
    ];

    const {
      isSharePage,
      activeTab,
      showSpecModal,
      showCouponModal,
      modalType,
      recommendList,
      goodsDetail,
      storeInfo,
      goodsMobileBodyVoList,
      isCollect,
      evaluateGoodsTotal,
      evaluateGoodsVoList,
      couponList,
      pending,
      isShowPlayIcon,
      token,
    } = this.state;
    const { goodsImageList, specJson } = goodsDetail;
    const {
      storeName,
      storeAvatarUrl,
      storeCollect,
      // gradeId,
      levelPic,
    } = storeInfo;
    // const gradeArr = new Array(gradeId).fill(1);
    return (
      !pending && (
        <div className={styles.container}>
          {/*顶部tab */}
          <div id="topBlock" style={{ position: "absolute", top: "0" }} />
          <Tabs
            tablist={tabList}
            activeTab={activeTab}
            handleClick={this.changeTab}
          />

          {/*走马灯 */}
          <Carousel infinite>
            {goodsImageList.map((item, index) =>
              item.isVideo ? (
                <div style={{ position: "relative" }} key={index}>
                  <video className={styles.video} id="video">
                    <source src={item.videoSrc} type="video/mp4"></source>
                  </video>
                  {isShowPlayIcon && (
                    <>
                      <img
                        className={styles.videoImg}
                        src={item.imageSrc || ""}
                        alt=""
                      />
                      <img
                        className={styles.play}
                        src={play}
                        alt=""
                        onClick={this.handlePlay}
                      />
                    </>
                  )}
                </div>
              ) : (
                <img
                  alt=""
                  src={item.imageSrc}
                  className={styles.productImg}
                  key={index}
                  onLoad={() => window.dispatchEvent(new Event("resize"))}
                />
              )
            )}
          </Carousel>

          {/*商品信息 */}
          <div className={styles.containerbox}>
            <GoodsInfo
              goodsDetail={goodsDetail}
              isCollect={isCollect}
              handlePutaway={this.handlePutaway}
              handleChangeFavoriteState={this.handleChangeFavoriteState}
              getGoodsInfo={this.getGoodsInfo}
              isSharePage={isSharePage}
            />

            {!isSharePage && (
              <div className={styles.block}>
                <div
                  className={styles.listItem}
                  onClick={() => this.handleShowSpecModal("spec")}
                >
                  <div className={styles.left}>
                    <span className={styles.titile}>选择规格</span>
                  </div>
                  <img src={arrow_icon} alt="" />
                </div>

                <div
                  className={styles.listItem}
                  onClick={this.handleShowCouponModal}
                >
                  <div className={styles.left}>
                    <span className={styles.title}>领劵</span>
                    {couponList.slice(0, 2).map((item, index) => (
                      <span className={styles.couponType} key={index}>
                        满{item.limitAmount}减{item.templatePrice}
                      </span>
                    ))}
                  </div>
                  <img src={arrow_icon} alt="" />
                </div>
              </div>
            )}

            {/*评论 */}
            <div className={styles.commentWrap} ref={this.comment}>
              <div
                id="commentBlock"
                style={{ position: "absolute", top: "-.6rem" }}
              />
              <div
                className={styles.listItem}
                onClick={this.handleToCommentList}
              >
                <div className={styles.left}>
                  <span className={styles.titile}>
                    宝贝评价 ({evaluateGoodsTotal})
                  </span>
                </div>
                <img src={arrow_icon} alt="" />
              </div>
              <div className={styles.commentList}>
                {evaluateGoodsVoList.map((item, index) => (
                  <div key={index}>
                    <CommentItem info={item} />
                  </div>
                ))}
              </div>
            </div>

            {/*店铺 */}
            <div className={styles.storeWrap}>
              {/*店铺card */}
              <div className={styles.store} onClick={this.handleToShop}>
                <div className={styles.left}>
                  <img
                    src={storeAvatarUrl}
                    onError={() => {
                      this.setState({
                        storeInfo: {
                          ...storeInfo,
                          storeAvatarUrl: require("../../assets/img/img-error.jpg"),
                        },
                      });
                    }}
                  />
                  <div className={styles.info}>
                    <span className={styles.name}>{storeName}</span>
                    <span className={styles.level}>
                      店铺等级：
                      <img src={levelPic} alt="" />
                      {/* {gradeArr.map((item, index) => (
                      <img src={diamond_icon} alt="" key={index} />
                    ))} */}
                    </span>
                    <span className={styles.favoriteNum}>
                      {storeCollect}人收藏
                    </span>
                  </div>
                </div>
                {<span className={styles.button}>进店</span>}
              </div>

              {/*商品推荐 */}
              <div className={styles.recommendStore}>
                <div className={styles.title}>商品推荐</div>
                <div className={styles.storeList}>
                  {recommendList.map((item, index) => (
                    <div className={styles.item} key={index}>
                      <RecommendItem
                        info={item}
                        isSharePage={isSharePage}
                        token={token}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/*商品详情 */}
            <div className={styles.detail} ref={this.detail}>
              <div
                id="detailBlock"
                style={{ position: "absolute", top: "-.1rem" }}
              />
              <div className={styles.title}>宝贝详情</div>
              {goodsMobileBodyVoList.map((item, index) => (
                <img
                  className={styles.detailImg}
                  src={item}
                  alt=""
                  key={index}
                />
              ))}
            </div>
          </div>
          {/*底部bar */}
          {!isSharePage && (
            <div className={styles.footer}>
              <div className={styles.iconList}>
                <div className={styles.iconItem} onClick={this.handleToShop}>
                  <img src={shop_icon} alt="" />
                  <span>店铺</span>
                </div>
                <div className={styles.iconItem} onClick={this.handelContact}>
                  <img src={contact_icon} alt="" />
                  <span>客服</span>
                </div>
                <div
                  className={styles.iconItem}
                  onClick={this.handleToShoppingCart}
                >
                  <img src={shoppingCart_icon} alt="" />
                  <span>购物车</span>
                </div>
              </div>
              <div className={styles.buttonList}>
                <span onClick={() => this.handleShowSpecModal("shopping")}>
                  加入购物车
                </span>
                <span onClick={() => this.handleShowSpecModal("buy")}>
                  {couponList.length ? "领劵购买" : "立即购买"}
                </span>
              </div>
            </div>
          )}

          {/*规格弹窗 */}
          <SpecModal
            showSpecModal={showSpecModal}
            handleCloseSpecModal={this.handleCloseSpecModal}
            quickBuy={this.quickBuy}
            addToShoppingCart={this.addToShoppingCart}
            modalType={modalType}
            goodsDetail={goodsDetail}
            getGoodsInfo={this.getGoodsInfo}
          />

          {/*优惠券弹窗 */}
          <Modal
            popup
            visible={showCouponModal}
            animationType="slide-up"
            onClose={this.handleCloseCouponModal}
            className={styles.modal}
          >
            <span className={styles.title}>优惠</span>
            <img
              className={styles.close}
              src={close_icon}
              alt=""
              onClick={this.handleCloseCouponModal}
            />
            <span className={styles.title1}>领取优惠券</span>
            <div className={styles.couponList}>
              {couponList.map((item, index) => (
                <div className={styles.couponItem} key={index}>
                  <CouponItem
                    info={item}
                    handleClick={this.handleReceiveCoupon}
                  />
                </div>
              ))}
            </div>
            <div className={styles.onekey}>
              <div onClick={this.onekeyCloseCouponModal}>一键全部领取</div>
            </div>
          </Modal>
        </div>
      )
    );
  }
}

export default ProductDetail;
