import React, { SFC, ChangeEvent } from "react";
import { ComponentProps } from "./index.interface";
import styles from "./index.module.less";

const InputBlock: SFC<ComponentProps> = ({
  title = "",
  value = "",
  placeholder = "",
  disabled = false,
  extra = "",
  titleWidth = "",
  type = "text",
  onChange = () => {},
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={styles.inputWrap}>
      <span className={styles.title} style={{ width: titleWidth }}>
        {title}
      </span>
      <div className={styles.right}>
        <input
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          type={type}
        />
        <div className={styles.extra}>{extra}</div>
      </div>
    </div>
  );
};

export default InputBlock;
