import React from "react";
import classNames from "classnames";
import qs from "qs";
import { Toast } from "antd-mobile";
import icon_search from "../../assets/img/store/icon_search.png";
import { getStore } from "../../api/store";
import BackToTop from "../../components/BackToTop";
import { Props, State } from "./index.interface";
import Home from "./module/Home";
import Goods from "./module/Goods";
import About from "./module/About";
import styles from "./index.module.less";

class Store extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.state = {
      storeId: Number(params.storeId) || 1, // TODO:
      searchValue: "",
      activeTab: "home",
      store: {
        storeName: "", // 店铺名称
        manager: "", // 店长
        levelPic: "", // 信用等级图片
        storeCollect: 0, // 店铺收藏数量
      },
      home: {
        storeBannerUrl: "", // banner
        hotGoods: [], // 热销
        recommendGoods: [], // 推荐
      },
      about: {
        manager: "", // 店长
        companyArea: "", // 地址(省份)
        companyCity: "", //地址(城市)
        storePhone: "", // 电话
      },
    };
  }
  componentDidMount() {
    if (this.state.storeId) {
      this.getData();
    } else {
      Toast.fail("获取失败", 1);
      setTimeout(() => {
        this.props.history.goBack();
      }, 1000);
    }
  }
  getData = async () => {
    const { storeId } = this.state;
    Toast.loading("加载中");
    const res: any = await getStore({ storeId });
    if (res.code === 200 && res.body) {
      const data = res.body;
      document.title = data.store.storeName;
      this.setState({
        store: {
          storeName: data.store.storeName,
          manager: data.about.manager,
          levelPic: data.levelPic,
          storeCollect: data.store.storeCollect,
        },
        home: {
          storeBannerUrl: data.store.storeBannerUrl,
          hotGoods: data.hotGoods,
          recommendGoods: data.recommendGoods,
        },
        about: {
          manager: data.about.manager,
          companyArea: data.about.companyArea,
          companyCity: data.about.companyCity,
          storePhone: data.about.storePhone,
        },
      });
    }
    Toast.hide();
  };
  handleGoodsItemClick = (commonId: number) => {
    this.props.history.push(`/product_detail?commonId=${commonId}&share=true`);
    window.scrollTo(0, 0);
  };
  render() {
    // const {
    //   location: { pathname },
    // } = this.props;
    const {
      storeId,
      searchValue,
      activeTab,
      store: {
        storeName, // 店铺名称
        manager, // 店长
        levelPic, // 信用等级图片
        storeCollect, // 店铺收藏数量
      },
      home,
      about,
    } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.storeName}>{storeName}</div>
          <div className={styles.description}>
            <div className={styles.left}>店长：{manager}</div>
            <div className={styles.right}>
              信用：
              <img src={levelPic} alt="信用等级" />
            </div>
            <div className={styles.right}>收藏：{storeCollect}</div>
          </div>
          <div className={styles.searchBar}>
            <img className={styles.searchIcon} src={icon_search} alt="" />
            <input
              type="text"
              placeholder=""
              onChange={e => {
                this.setState({
                  searchValue: e.target.value,
                  activeTab: "goods",
                });
              }}
            />
            <span>搜索</span>
          </div>
          <div className={styles.tabs}>
            <div
              className={classNames(
                styles.tab,
                activeTab === "home" && styles.activeTab
              )}
              onClick={() =>
                this.setState({
                  activeTab: "home",
                })
              }
            >
              首页
            </div>
            <div
              className={classNames(
                styles.tab,
                activeTab === "goods" && styles.activeTab
              )}
              onClick={() =>
                this.setState({
                  activeTab: "goods",
                })
              }
            >
              商品
            </div>
            <div
              className={classNames(
                styles.tab,
                activeTab === "about" && styles.activeTab
              )}
              onClick={() =>
                this.setState({
                  activeTab: "about",
                })
              }
            >
              关于
            </div>
          </div>
        </div>
        <div>
          {activeTab === "home" && (
            <Home
              {...home}
              onGoodsItemClick={(commonId: number) =>
                this.handleGoodsItemClick(commonId)
              }
            />
          )}
          {activeTab === "goods" && (
            <Goods
              storeId={storeId}
              searchValue={searchValue}
              onGoodsItemClick={(commonId: number) =>
                this.handleGoodsItemClick(commonId)
              }
            />
          )}
          {activeTab === "about" && <About {...about} />}
        </div>
        <BackToTop />
      </div>
    );
  }
}
export default Store;
