import React from "react";
import qs from "qs";
import phone_back from "../../assets/img/phone_back.png";
import { State } from "./index.interface";

import styles from "./index.module.less";
class Preview extends React.Component<PageProps, State> {
  constructor(props: PageProps) {
    super(props);
    this.state = {
      url: "",
    };
  }

  componentDidMount() {
    document.title = "无界时空链";

    const { location } = this.props;
    const params = qs.parse(location.search, { ignoreQueryPrefix: true });
    this.setState({
      url: params.url || "",
    });
  }

  // 跳转协议
  handleToAggreement = () => {
    window.location.href = "http://resource.wujieuni.com/xieyi/hetong.html";
  };

  render() {
    const { url } = this.state;
    return (
      <main className={styles.main}>
        <div className={styles.imgBack}>
          <img src={phone_back} alt="" />
          <iframe title="详情" src={url} frameBorder={0}></iframe>
        </div>
      </main>
    );
  }
}

export default Preview;
